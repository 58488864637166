.FIE_text-tool-button {
    display: none !important;
}

.FIE_image-tool-button {
    display: none !important;
}

.FIE_rect-tool-button {
    display: none !important;
}

.FIE_ellipse-tool-button {
    display: none !important;
}

.FIE_polygon-tool-button {
    display: none !important;
}

.FIE_pen-tool-button {
    display: none !important;
}

.FIE_line-tool-button {
    display: none !important;
}

.FIE_arrow-tool-button {
    display: none !important;
}

.FIE_annotation-option-triggerer[title="Stroke"] {
    display: none !important;
}

.FIE_annotation-option-triggerer[title="Opacity"] {
    display: none !important;
}

.FIE_annotation-option-triggerer[title="Shadow"] {
    display: none !important;
}

.FIE_annotation-option-triggerer[title="Position"] {
    display: none !important;
}

.FIE_annotation-option-triggerer[title="Text spacings"] {
    display: none !important;
}

.FIE_annotation-option-triggerer[title="Text alignment"] {
    display: none !important;
}

.FIE_text-font-family-option {
    display: none !important;
}